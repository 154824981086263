import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"

const CheckoutFailure = () => (
  <Layout>
    <Header/>

    <p className="section-body text-gray-400">
      There was an error while trying to process your payment. 🤕 <br/> <br/>
      Maybe you've tried subscribing with an email different than the one you used for Linksaber (in which case you should retry with that one).<br/> <br/>
      Or maybe you're already subscribed (in which case you're a champion already and you can go on with you life). <br/> <br/>
    </p>
  </Layout>
)


export default CheckoutFailure